import { addMinutes, isAfter } from 'date-fns';
import hash from 'hash-it';

export class CacheStateService {
  private readonly CACHE_DURATION_IN_MINUTES = 5;
  private readonly DEFAULT_KEY = 'DEFAULT';

  private cache: {
    [key: string]: {
      expires: Date | null;
      object: any;
    };
  } = {};

  protected getObjectHash(object?: any): string {
    return object ? hash(object).toString() : this.DEFAULT_KEY;
  }

  protected setCacheState(key: string, object?: any): void {
    const expires = new Date(addMinutes(new Date(), this.CACHE_DURATION_IN_MINUTES));
    this.cache[key] = { expires, object };
  }

  protected getCacheState(key: string, object?: any): boolean {
    const item = this.cache[key];
    const sameHash = item && this.getObjectHash(object) === this.getObjectHash(item.object);
    const hasCache = !!(item?.expires && !isAfter(new Date(), item.expires) && sameHash);
    if (!hasCache) {
      this.setCacheState(key, object);
    }

    return hasCache;
  }

  protected clearCache(key: string): void {
    this.cache[key] = { expires: null, object: null };
  }
}
