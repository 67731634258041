import { Injectable, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, take } from 'rxjs/operators';
import { CacheStateService } from './cache-state.service';

@Injectable()
export class StateService<State> extends CacheStateService implements OnDestroy {
  private readonly previousStateQueue: State[] = [];
  private readonly state$: ReplaySubject<State>;

  protected get state(): State {
    let value: State;

    this.state$.pipe(take(1)).subscribe((state) => {
      value = state;
    });

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return value!;
  }

  public get hasState(): boolean {
    return !!this.state;
  }

  constructor() {
    super();
    this.state$ = new ReplaySubject<State>(1);
  }

  protected select<K>(mapFn: (state: State) => K): Observable<K> {
    return this.state$.asObservable().pipe(
      map((state: State) => mapFn(state)),
      distinctUntilChanged(),
    );
  }

  protected setState(newState: Partial<State>): void {
    this.addToPreviousState(this.state);
    this.state$.next({
      ...this.state,
      ...newState,
    });
  }

  protected getAndSetCacheState(key: string, object?: any): boolean {
    return !!(this.getCacheState(key, object) && this.state);
  }

  protected setFullState(newState: State): void {
    this.addToPreviousState(this.state);
    this.state$.next(newState);
  }

  protected rollback(): void {
    if (this.previousStateQueue?.length) {
      const previousState: State | undefined = this.previousStateQueue.pop();
      if (previousState) {
        this.state$.next(previousState);
      }
    }
  }

  private addToPreviousState(state: State): void {
    const removeFirstFromQueue: boolean = this.previousStateQueue?.length > 10;
    if (removeFirstFromQueue) {
      this.previousStateQueue.shift();
    }

    this.previousStateQueue.push(state);
  }

  ngOnDestroy(): void {
    this.state$.complete();
  }
}
