export class StateUtilsService {
  public static replaceInState<T extends { id: number | string }>(objects: T[], objectToReplace: T): T[] {
    return objects.map((objectIterated: T) =>
      objectToReplace.id === objectIterated.id ? objectToReplace : objectIterated,
    );
  }

  public static filterInState<T extends { id: number | string }>(objects: T[], objectToReplace: T): T[] {
    return objects.filter((objectIterated: T) => objectToReplace.id !== objectIterated.id);
  }

  public static addInState<T extends { id: number | string }>(objects: T[], objectToAdd: T, sortFn?: any): T[] {
    const unsortedObjects: T[] = [...objects, objectToAdd];
    return sortFn ? unsortedObjects.sort(sortFn) : unsortedObjects;
  }
}
